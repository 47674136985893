import React from 'react'

export const pageData = {
  title: '10 Best Agent Image X Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Real Estate Websites',
      url: '/real-estate-websites/',
    },
    {
      text: '10 Best Agent Image X Websites',
      url: '/real-estate-websites/10-best-agent-image-x-websites/',
    },
  ],
  uri: '/real-estate-websites/10-best-agent-image-x-websites/',
  lcf: {
    version: 'LCF03',
    model: 'Jennifer',
    title: 'Free Consultation',
    subtitle: 'Get In Touch With Us For A',
    submitLabel: 'Book your free consultation',
    leadName: 'Consultation: 10 Best Agent Image X Websites',
    layout: 'B',
  },
}

export const bannerData = {
  heading: {
    text: '10 Best Agent Image X Websites',
    className: 'heading-5',
  },
  subheading: {
    text: 'Website Templates For Starter Agents',
    className: 'subtitle-7',
  },
}

export const listData = {
  heading: {
    text: 'Professional Website Themes',
    className: 'heading-5',
  },
  subheading: {
    text: 'Simple. Sleek. Sophisticated.',
    className: 'subtitle-7',
  },
  list: {
    titleClassName: 'heading-3',
    locationClassName: 'subtitle-5',
    descriptionClassName: 'default-body large',
    buttonText: 'GET PRICING & FEATURES',
    identifierLayout: 'aix',
    scrollLaptopBackcover: 'aix',
    identifierDelay: { update: 100, intersect: 250 },
    itemLayout: 'B',
    items: [
      {
        title: 'Mark Klecka',
        location: 'Sarasota, FL',
        description:
          'Mark Kleckla wanted a website that effectively showed off both his brand and his listings. Luckily, our Metropolitan theme does both with style to spare. This visual-centric theme allows Mark to highlight Florida’s beautiful shores and the equally impressive homes that line them. What’s more, we were able to stay true to Mark’s own sense of style while incorporating Coldwell Banker’s brand signatures on the website.',
        identifier: 'Royale',
        identifierLink: 'https://aix-royale.agentimagex.com/',
        scrollImgHeight: 5997,
      },
      {
        title: 'Michelle Liberman',
        location: 'Los Angeles, CA',
        description:
          'Vega’s straightforward but stylish design is a perfect fit for straight-talking former Brooklynite Michelle Lieberman. Clean and uncluttered, its layout is indicative of the seamless and stress-free service Michelle offers to clients. This theme’s photo-centric design also focuses the spotlight where it rightfully belongs: on the gorgeous luxury homes for sale. Ample white space, meanwhile, adds a refreshing quality that reflects the laidback lifestyle of coastal Los Angeles.',
        identifier: 'Quantum',
        identifierLink: 'https://aix-quantum.agentimagex.com/',
        scrollImgHeight: 5549,
      },
      {
        title: 'Kilian Rief',
        location: 'Atlanta, GA',
        description:
          'Immersive and captivating, KilianRief.com immediately grabs the user’s attention. Gorgeous property walk-throughs and aerial shots of Atlanta homes in the main slider gives a glimpse of Kilian Rief’s expertise in marketing a home. Eye-catching animation coupled with straightforward call-to-action buttons and IDX features provide an engaging and informative user experience. True to its Legacy theme, the gold and grey color palette gives the site a classic and timeless feel.',
        identifier: 'Seneca',
        identifierLink: 'https://aix-seneca.agentimagex.com/',
        scrollImgHeight: 5228,
      },
      {
        title: 'Angel Diorgani',
        location: 'Key Largo, FL',
        description:
          "Our Iconic theme’s image-centric design allows Angel DiGiorgo to showcase the Florida Keys’ picture-perfect sights. From the banner section, to the featured communities and listings, to the Instagram-feed integration, visitors have plenty of opportunities to marvel at the stunning views and ultra-deluxe homes in this locale. Of course, her website isn’t just about aesthetic appeal; a quick search button in the banner image makes looking for listings a breeze, while a video showcase section gives people a deeper look at what it's<br/> like to live in this coveted community.",
        identifier: 'Hamilton',
        identifierLink: 'https://aix-hamilton.agentimagex.com/',
        scrollImgHeight: 5246,
      },
      {
        title: 'Ferretti Real Estate Group',
        location: 'Indian Wells, CA',
        description:
          'FerretiRealEstate.com is all about balance: sleek professionalism complemented by a fresh and youthful aesthetic. The tasteful use of white space highlights the vibrancy of Coachella Valley properties in the photos and makes for better content readability. Moreover, the layout of the Element Theme keeps the site simple without being boring and visually stimulating without being too distracting. These design features make the user feel at ease as they navigate the site.',
        identifier: 'Royale',
        identifierLink: 'https://aix-royale.agentimagex.com/',
        scrollImgHeight: 5827,
      },
      {
        title: 'Shanna Jadooram',
        location: 'Sarasota, FL',
        description:
          'For an agent who places equal importance on expertise and personal touch, only a website template that marries form and function will do. And that’s exactly what our Maven template delivers. With a hardworking banner section and a Quick Search feature just beneath it, Maven makes it easy for visitors to find the perfect home in Central Florida. At the same time, the timeless black-and-white aesthetic and postcard-like visual design evoke undeniable sophistication.',
        identifier: 'Quantum',
        identifierLink: 'https://aix-quantum.agentimagex.com/',
        scrollImgHeight: 5997,
      },
      {
        title: 'City to the Coast',
        location: 'San Antonio, TX',
        description:
          'Using shades of gray all throughout the site, Crystal Medina’s Florence-themed CitytotheCoast.com exudes seriousness and sophistication – a great match for the San Antonio luxury property market. Against this backdrop, the lively colors of upscale homes and beachfront settings truly pop out and captivate. Readability on this site also benefits from the muted contrast, lending to an improved overall user experience.',
        identifier: 'Seneca',
        identifierLink: 'https://aix-seneca.agentimagex.com/',
        scrollImgHeight: 2187,
      },
      {
        title: 'DNG Is The Key',
        location: 'South Bay, CA',
        description:
          'DNGIsKey.com captures the South Bay real estate market and lifestyle effortlessly with its fun yet sophisticated style. The versatility of the Berlin Agent Pro theme makes it easy for Deborah and Gulshen to convey their brand message loud and clear. The site was made to complement their marketing materials focusing on the agents’ area of expertise. Organized and compact, the site has all the essentials laid out for the best user experience.',
        identifier: 'Hamilton',
        identifierLink: 'https://aix-hamilton.agentimagex.com/',
        scrollImgHeight: 1744,
      },
      {
        title: 'Hot Illinois Homes',
        location: 'Schaumberg, IL',
        description:
          'This Chicagoland real estate website lives up to its name as its hot orange-and-gray theme making its features come alive. Navigation is easy, thanks to a rich homepage that showcases the best that this site offers above the fold. The Milan template makes good use of symmetrical and cleanly spaced grid layouts, ensuring that site users are never overwhelmed.',
        identifier: 'Royale',
        identifierLink: 'https://aix-royale.agentimagex.com/',
        scrollImgHeight: 1776,
      },
      {
        title: ' Mat Just',
        location: 'Orange County, CA',
        description:
          'Inspired by the relaxing coastal lifestyle, Mat Just’s website uses a refreshing coral blue and white color scheme and a streamlined design to showcase some of Orange County’s finest homes. Every element is arranged strategically from the dropdown menus, links to important sections, featured listings, blog post previews, and other content, creating a seamless flow and balanced look and feel, all without sacrificing style.',
        identifier: 'Quantum',
        identifierLink: 'https://aix-quantum.agentimagex.com/',
        scrollImgHeight: 1375,
      },
    ],
  },
}

export const featuresData = {
  heading: {
    text: 'Where Style Meets Savings',
    className: 'heading-2',
  },
  subheading: {
    text: 'Agent Image X Real Estate Websites',
    className: 'subtitle-7',
  },
  description: {
    text:
      'A powerful combination where form meets function. A stylish and dynamic package with express site setup, delivering instant results.',
    className: 'subtitle-5',
  },
  imgAlt: 'Douglas Elliman Screenshots on laptop and phone',
  best: [
    {
      content:
        'A single agent looking for a solid, easy to build website that gets the job done',
      check: true,
    },
    {
      content:
        'Professionals who have an urgency to finish a website quickly and have no requirements for custom design.',
      check: true,
    },
  ],
  highlights: [
    {
      content:
        'Choose from sixteen upscale templates that include a pre-designed home page<br/> and inner page layout',
      icon: 'doc-detail',
    },
    {
      content: 'Mobile optimized design for easy viewing on small screens',
      icon: 'phone',
    },
    {
      content: 'IDX integration using the vendor of your choice',
      icon: 'settings',
    },
  ],
  buttonText: 'View more features',
  buttonLink: '/real-estate-websites/agent-image-x/',
}

export const closingData = {
  heading: 'Ready to choose your Agent Image X theme?',
  paragraph:
    'Take your pick from our list of website design templates. We have styles fit for every agent.',
  button: {
    text: 'Choose Agent Image X',
    link: '/real-estate-websites/agent-image-x/',
  },
  alt: 'ARC Realty screenshots on devices',
}
