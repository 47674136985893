import React from 'react'
import { getImage } from 'gatsby-plugin-image'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import RealEstateBanner from '@components/global/banner/RealEstateBanner'
import Best10RealEstate from '@components/global/gallery/Best10RealEstate'
import Best10Features from '@components/global/product/Best10Features'
import { Closing } from '@components/pages/best-10-aix'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import {
  pageData as page,
  bannerData,
  listData,
  featuresData,
  closingData,
} from '@src/data/Best10AIX'
import useBest10AgentImageXMetadata from '@hooks/best-10-aix-metadata'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './10BestAgentImageX.module.scss'

const Best10AgentImageXWebsites = () => {
  const {
    bannerBG,
    bannerImg,
    bannerImgDesktop,
    listMarkLaptop,
    listMarkTablet,
    listMarkPhone,
    listMichelleLaptop,
    listMichelleTablet,
    listMichellePhone,
    listKilianLaptop,
    listKilianTablet,
    listKilianPhone,
    listAngelLaptop,
    listAngelTablet,
    listAngelPhone,
    listFerrettiLaptop,
    listFerrettiTablet,
    listFerrettiPhone,
    listShannaLaptop,
    listShannaTablet,
    listShannaPhone,
    listCityLaptop,
    listCityTablet,
    listCityPhone,
    listDngLaptop,
    listDngTablet,
    listDngPhone,
    listHotLaptop,
    listHotTablet,
    listHotPhone,
    listMatLaptop,
    listMatTablet,
    listMatPhone,
    featuresImg,
    featuresAccent,
    closingBG,
    closingImg,
    lcfBGPhone,
  } = useBest10AgentImageXMetadata()

  const imgListData = [
    {
      laptop: listMarkLaptop,
      tablet: listMarkTablet,
      phone: listMarkPhone,
    },
    {
      laptop: listMichelleLaptop,
      tablet: listMichelleTablet,
      phone: listMichellePhone,
    },
    {
      laptop: listKilianLaptop,
      tablet: listKilianTablet,
      phone: listKilianPhone,
    },
    {
      laptop: listAngelLaptop,
      tablet: listAngelTablet,
      phone: listAngelPhone,
    },
    {
      laptop: listFerrettiLaptop,
      tablet: listFerrettiTablet,
      phone: listFerrettiPhone,
    },
    {
      laptop: listShannaLaptop,
      tablet: listShannaTablet,
      phone: listShannaPhone,
    },
    {
      laptop: listCityLaptop,
      tablet: listCityTablet,
      phone: listCityPhone,
    },
    {
      laptop: listDngLaptop,
      tablet: listDngTablet,
      phone: listDngPhone,
    },
    {
      laptop: listHotLaptop,
      tablet: listHotTablet,
      phone: listHotPhone,
    },
    {
      laptop: listMatLaptop,
      tablet: listMatTablet,
      phone: listMatPhone,
    },
  ]
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767

  return (
    <LayoutInnerPage
      hasBlackTransparentNav
      noFloatingShareButtons
      wrapperClassName={cx.wrapper}
    >
      <Seo title={page.title} uri={page.uri} />
      <RealEstateBanner
        hasDarkText
        breadcrumbs={page.breadcrumbs}
        wrapperClassName={cx.banner}
        heading={bannerData.heading.text}
        subheading={bannerData.subheading.text}
        bg={extractImage(bannerBG)}
        img={extractImage(isDesktop ? bannerImgDesktop : bannerImg)}
      />
      <Best10RealEstate
        hasIdentifier
        hasScrollLaptop
        wrapperClassName={cx.list}
        itemLayout={listData.list.itemLayout}
        heading={listData.heading.text}
        subheading={listData.subheading.text}
        buttonText={listData.list.buttonText}
        items={listData.list.items}
        imgItems={imgListData}
        identifierLayout={listData.list.identifierLayout}
        identifierLink={listData.list.identifierLink}
        scrollLaptopBackcover={listData.list.scrollLaptopBackcover}
      />
      <Best10Features
        hasButton
        hasDarkHeaderText
        wrapperClassName={cx.features}
        heading={featuresData.heading.text}
        subheading={featuresData.subheading.text}
        description={featuresData.description.text}
        img={extractImage(featuresImg)}
        accent={extractImage(featuresAccent)}
        imgAlt={featuresData.imgAlt}
        bestItems={featuresData.best}
        highlightsItems={featuresData.highlights}
        buttonText={featuresData.buttonText}
        buttonLink={featuresData.buttonLink}
      />
      <Closing
        bg={extractImage(closingBG)}
        heading={closingData.heading}
        paragraph={closingData.paragraph}
        buttonText={closingData.button.text}
        buttonLink={closingData.button.link}
        img={extractImage(closingImg)}
        alt={closingData.alt}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.model}
        background={isPhone && getImage(lcfBGPhone)}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Best10AgentImageXWebsites
